import NextImage from 'next/image';
import { CSSProperties, PropsWithoutRef } from 'react';
import { cn } from '@/utils/helpers/cn';
import { FeaturedImageFragFragmentDoc, MediaItemFragFragment } from '@graphqlTypes/graphql';

export const Image = ( {
	alt,
	image,
	width,
	height,
	className,
	priority,
	fill,
	...props
}: PropsWithoutRef< {
	alt?: string;
	className?: string;
	fill?: boolean;
	height?: number;
	image?: MediaItemFragFragment;
	priority?: boolean;
	sizes?: string;
	style?: CSSProperties;
	width?: number;
} > ) => {
	const src = image?.sourceUrl;
	const altText = alt || image?.altText || '';
	const originalWidth = image?.mediaDetails?.width;
	const originalHeight = image?.mediaDetails?.height;

	const maxWidth = 1200; // From tailwind.config.js

	const imageProps: {
		width?: number;
		height?: number;
		fill?: boolean;
		sizes?: string;
	} = {};

	// Set the width and the height of the component
	if ( width && height ) {
		imageProps.width = width;
		imageProps.height = height;
	} else if ( width && originalHeight && originalWidth ) {
		imageProps.width = width;
		imageProps.height = originalHeight * ( width / originalWidth );
	} else if ( height && originalHeight && originalWidth ) {
		imageProps.width = originalWidth * ( height / originalHeight );
		imageProps.height = height;
	}

	// If there is no width or height, fill the container
	if ( fill || ! imageProps.width || ! imageProps.height ) {
		imageProps.fill = true;
		imageProps.sizes = `(max-width: ${ Math.min(
			imageProps?.width || maxWidth,
			maxWidth
		) }px) 100vw, ${ Math.min( imageProps?.width || maxWidth, maxWidth ) }px`;
		delete imageProps.width;
		delete imageProps.height;
	}

	return src ? (
		<NextImage
			className={ cn( className, imageProps?.fill && 'object-cover' ) }
			src={ src }
			{ ...props }
			alt={ altText }
			priority={ priority }
			{ ...imageProps }
		/>
	) : null;
};

Image.fragments = {
	entry: FeaturedImageFragFragmentDoc,
	key: 'FeaturedImageFrag',
};
