import { library } from '@fortawesome/fontawesome-svg-core';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { IconName, IconPrefix, faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Parser, { domToReact, HTMLReactParserOptions, Element, DOMNode } from 'html-react-parser';
import { CSSProperties } from 'react';
import { Image as FeaturedImage } from '@/components/elements/Image/Image';
import { Link } from '@/components/elements/Link/Link';
import { getImageSizeFromAttributes } from '@/utils/helpers/getImageSizeFromAttributes';
import { getInternalUrl } from '@/utils/helpers/getInternalUri';
import { getStyleObjectFromString } from '@/utils/helpers/getStyleObjectFromString';
import type { MediaItemFragFragment } from '@graphqlTypes/graphql';

// // Workaround to get the library to work with Next.js
library.add( faSlack, faPaperPlane );

const options: HTMLReactParserOptions = {
	replace: ( domNode ) => {
		if ( domNode instanceof Element ) {
			const { attribs, children, name, type } = domNode;
			const { class: className, style, ...attributes } = attribs;

			const styleObject = style ? getStyleObjectFromString( style ) : undefined;

			// Image Component
			if ( type === 'tag' && name === 'img' ) {
				const internalUri = getInternalUrl( attribs?.src );
				const { width, height } = getImageSizeFromAttributes( attribs );

				if ( internalUri ) {
					const imageAttributes = {
						sourceUrl: attribs?.src,
						mediaDetails: {
							width,
							height,
						},
					} as MediaItemFragFragment;

					const shouldFill = width && height ? false : true;

					return (
						<FeaturedImage
							{ ...attributes }
							image={ imageAttributes }
							height={ height }
							width={ width }
							className={ className }
							fill={ shouldFill }
							style={ styleObject as CSSProperties }
						/>
					);
				}
			}

			// Link component
			if ( type === 'tag' && name === 'a' ) {
				return (
					<Link
						{ ...attributes }
						className={ className }
						href={ attribs.href }
						style={ styleObject as CSSProperties }
					>
						{ domToReact( children as DOMNode[], options ) }
					</Link>
				);
			}

			//FontAwesomeIcon
			if ( type === 'tag' && name === 'i' ) {
				let classes = className.split( ' ' );

				// The libname is always the first class. libnames are just the first three letters of the class without the -. E.g fa-regular becomes far. fa-solid becomes fas. Other
				const libName = classes?.[ 0 ]?.replace( 'fa-', 'fa' ).substring( 0, 3 ) as IconPrefix;

				classes = classes.splice( 1 );

				// Look for the fa-<icon> class. If it exists set icon to the value of the class, and remove it from the classes array.

				const icon = classes?.[ 0 ]?.replace( 'fa-', '' ) as IconName;
				classes = classes.splice( 1 );

				if ( icon && libName ) {
					return <FontAwesomeIcon icon={ [ libName, icon ] } className={ classes.join( ' ' ) } />;
				}
			}
		}
	},
};

export const Parse = ( { html }: { html: string } ) => {
	return <>{ Parser( html, options ) }</>;
};
