import { useBlocksTheme } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksProvider';
import {
	WordPressBlocksViewer,
	ContentBlock,
	BlockWithAttributes,
} from '@faustwp/blocks/dist/mjs/components/WordPressBlocksViewer';
import { getStyles } from '@faustwp/blocks/dist/mjs/utils/get-styles/getStyles';
import { PropsWithChildren, createElement } from 'react';
import { DefaultBlock } from '@/features/WpBlocks/components/DefaultBlock';
import { Parse } from '@/lib/parser';
import { getClassNamesFromString } from '@/utils/helpers/getClassNamesFromString';
import { type CoreGroupFragFragment } from '@graphqlTypes/graphql';

export type CoreGroupFragmentProps = ContentBlock & CoreGroupFragFragment;

export const Tag = ( {
	name,
	className,
	style,
	children,
}: PropsWithChildren< {
	name?: string | null;
	className?: string;
	style?: React.CSSProperties;
} > ) => {
	if ( ! name ) {
		return <>{ children }</>;
	}

	return createElement( name, { style, className }, children );
};

export const CoreGroup = ( props: CoreGroupFragmentProps ) => {
	const theme = useBlocksTheme();
	const style = getStyles( theme, { ...( props as unknown as BlockWithAttributes ) } );
	const { attributes, innerBlocks, renderedHtml, cssClassNames } = props;

	const tagName = attributes?.tagName || 'div';

	const renderedClassNames = getClassNamesFromString( renderedHtml ?? '' );
	const classNames = Array.from(
		new Set( [ ...renderedClassNames, ...( cssClassNames || [] ), attributes?.className ] )
	)
		.filter( Boolean )
		.join( ' ' );

	return (
		<>
			{ innerBlocks?.length && (
				<Tag name={ tagName } className={ classNames } style={ style }>
					<WordPressBlocksViewer
						blocks={ innerBlocks as ContentBlock[] }
						fallbackBlock={ DefaultBlock }
					/>
				</Tag>
			) }

			{ ! innerBlocks?.length && !! renderedHtml && <Parse html={ renderedHtml } /> }
		</>
	);
};
