// From https://github.com/blakewilson/faust-gutenberg-decode-demo/blob/main/utils/getImageSizeProps.js

export type ImageSizeAttributes = {
	sizeSlug?: string;
	width?: string;
	height?: string;
};
export const getImageSizeFromAttributes = (
	attributes: ImageSizeAttributes
): {
	width: number;
	height: number;
} => {
	const sizeSlug = attributes?.sizeSlug;
	const widthPx = attributes?.width ? getPxForSizeAttribute( attributes?.width ) : undefined;
	const heightPx = attributes?.height ? getPxForSizeAttribute( attributes?.height ) : undefined;
	if ( sizeSlug ) {
		return {
			width: widthPx ?? imageSizeToWidth[ sizeSlug ],
			height: heightPx ?? imageSizeToHeight[ sizeSlug ],
		};
	}
	return {
		width: widthPx ?? 600,
		height: heightPx ?? 600,
	};
};

const imageSizeToWidth: {
	[ key: string ]: number;
} = {
	thumbnail: 150,
	medium: 300,
	large: 600,
	'full-size': 2560,
};

const imageSizeToHeight: {
	[ key: string ]: number;
} = {
	thumbnail: 150,
	medium: 300,
	large: 600,
	'full-size': 2560,
};

export function getPxForSizeAttribute( sizeAttribute: string ): number {
	const sizeFloat = parseFloat( sizeAttribute );
	const unit = sizeAttribute.replace( sizeFloat.toString(), '' );
	let size;

	switch ( unit ) {
		case 'em':
		case 'rem':
		case '%':
			size = sizeFloat * 16;
			break;
		case 'pt':
			size = sizeFloat * 1.33;
			break;
		case 'pc':
			size = sizeFloat * 16;
			break;
		case 'cm':
			size = sizeFloat * 37.8;
			break;
		case 'mm':
			size = sizeFloat * 3.78;
			break;
		case 'px':
		case '':
		default:
			size = sizeFloat;
			break;
	}

	return size;
}
