import NextLink, { LinkProps } from 'next/link';
import { HtmlHTMLAttributes, PropsWithChildren } from 'react';
import { getInternalUrl } from '@/utils/helpers/getInternalUri';

export const Link = ( {
	href,
	className,
	children,
	...rest
}: PropsWithChildren<
	LinkProps &
		HtmlHTMLAttributes< HTMLAnchorElement > & {
			href: string;
			className?: string;
			target?: React.HTMLAttributeAnchorTarget;
		}
> ) => {
	// if href is an internal link, use next/link
	const internalUrl = href?.startsWith( '/' ) ? href : getInternalUrl( href );

	if ( internalUrl ) {
		return (
			<NextLink href={ internalUrl } className={ className } { ...rest }>
				{ children }
			</NextLink>
		);
	}

	// if href is an external link, use <a> tag
	return (
		<a href={ href } className={ className } { ...rest }>
			{ children }
		</a>
	);
};
